<template>
  <div class="container">
    <div v-if="!show_auth">
      <div>
        <div class="flex justify-center items-center py-12">
          <div>
            <p class="font-light text-center">Today</p>
            <h2 class="text-3xl text-gray-700 font-bold">{{ today }}</h2>
          </div>
        </div>

        <!-- Tab Buttons -->
        <div class="flex justify-center mb-4">
          <button
            class="px-4 py-2 m-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-700 focus:outline-none focus:shadow-outline"
            :class="{ 'bg-blue-700': activeTab === 'credit' }"
            @click="activeTab = 'credit'">
            Credit Cards
          </button>
          <button
            class="px-4 py-2 m-2 font-bold text-white bg-green-500 rounded hover:bg-green-700 focus:outline-none focus:shadow-outline"
            :class="{ 'bg-green-700': activeTab === 'debit' }"
            @click="activeTab = 'debit'">
            Debit Cards
          </button>
        </div>

        <div class="flex justify-center items-center">Showing {{ activeTab == "debit" ? "Debit" : "Credit" }} ({{ filteredCards.length }}) Cards</div>

        <div class="bg-white min-h-screen flex justify-center items-center flex-column gap-16">
          <div
            v-for="(card, index) in filteredCards"
            :key="index"
            :style="cardBackgroundStyle(card)"
            class="text-shadow w-[500px] h-80 m-auto rounded-xl relative text-white shadow-2xl transition-transform transform hover:scale-110 bg-black">
            <div class="w-full px-8 absolute top-8">
              <div class="flex justify-between">
                <div class="">
                  <p class="font-light">Name</p>
                  <p class="font-medium tracking-widest pointer" @click="copy_value(card.card_info.name)">{{ card.card_info.name }}</p>
                </div>
                <img class="w-14 h-14" :src="card.card_info.img" />
              </div>
              <div class="flex justify-between">
                <div class="pt-1">
                  <p class="font-light">Card Number</p>
                  <p class="font-medium tracking-more-wider pointer" @click="copy(card.card_info.card_no)">
                    {{ card.card_info.card_no }}
                  </p>
                </div>
                <!-- Only display Repay Days for credit cards -->
                <div v-if="card.card_type === 'credit'" class="pt-1">
                  <p class="font-light">Repay Days</p>
                  <p class="font-medium tracking-more-wider">{{ card.days_left_for_repayment }}</p>
                </div>
              </div>
              <div class="pt-6">
                <div class="flex justify-between">
                  <div class="">
                    <p class="font-light text-xs">Bank</p>
                    <p class="font-medium tracking-wider text-sm">{{ card.card_info.bank_name }}</p>
                  </div>
                  <div class="">
                    <p class="font-light text-xs">Expiry</p>
                    <p class="font-medium tracking-wider text-sm pointer" @click="copy(card.card_info.valid_till)">{{ card.card_info.valid_till }}</p>
                  </div>

                  <div class="">
                    <p class="font-light text-xs">CVV</p>
                    <p class="font-bold tracking-more-wider text-sm pointer" @click="copy(card.card_info.cvv)">{{ card.card_info.cvv }}</p>
                  </div>
                </div>
              </div>
              <!-- Only display billing and payment details for credit cards -->
              <div v-if="card.card_type === 'credit'" class="pt-6">
                <div class="flex justify-between">
                  <div class="">
                    <p class="font-light text-xs">Billing Cycle</p>
                    <p class="font-medium tracking-wider text-sm">{{ card.billing_cycle_dates }}</p>
                    <p class="font-medium tracking-wider text-sm">{{ card.days_left_for_billing_cycle }} Days Left</p>
                  </div>

                  <div class="" v-if="card.statement_pass" >
                    <p class="font-light text-xs">statement_pass</p>
                    <p class="font-medium tracking-wider text-sm" @click="copy(card.statement_pass)">{{ card.statement_pass }}</p>
                  </div>

                  <div class="">
                    <p class="font-light text-xs">Due Date</p>
                    <p class="font-medium tracking-wider text-sm">{{ getDate(card.payment_due_date) }}</p>
                    <p class="font-medium tracking-wider text-sm">{{ card.days_left_for_repayment }} Days Left</p>
                  </div>
                </div>
              </div>

              <div v-if="card.card_type === 'debit'" class="pt-6">
                <div class="flex justify-between">
                  <div class="">
                    <p class="font-light text-xs">Acc No.</p>
                    <p class="font-medium tracking-wider text-sm pointer" @click="copy(card.account_info.account_no)">{{ card.account_info.account_no }}</p>
                  </div>

                  <div class="" v-if="card.account_info.c_id">
                    <p class="font-light text-xs">C_ID</p>
                    <p class="font-medium tracking-wider text-sm pointer" @click="copy(card.account_info.c_id)">{{ card.account_info.c_id }}</p>
                  </div>

                  <div class="">
                    <p class="font-light text-xs">IFSC</p>
                    <p class="font-medium tracking-wider text-sm pointer" @click="copy(card.account_info.ifsc)">{{ card.account_info.ifsc }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex justify-center items-center h-[100vh]" v-if="show_auth">
      <div class="w-full max-w-xs">
        <form @submit.prevent="validatePassword()" class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <div class="mb-6">
            <label class="block text-gray-700 text-sm font-bold mb-2" for="password"> Password </label>
            <input
              class="shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
              id="password"
              type="password"
              v-model="userInput"
              placeholder="******************" />
          </div>
          <div class="flex items-center justify-center">
            <button
              @click="validatePassword()"
              @keypress.=""
              class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="button">
              Sign In
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import CryptoJS from "crypto-js";
export default {
  name: "App",
  data() {
    return {
      activeTab: "credit", // Default tab
      today: "",
      cards: [
        // {
        //   statement_generated_on: 8,
        //   billing_cycle_start_date: "",
        //   billing_cycle_end_date: "",
        //   billing_cycle_dates: "",
        //   payment_due_on: 28,
        //   payment_due_date_is_in_same_month_as_billing_cycle_end_date: true,
        //   payment_due_date: "",
        //   days_left_for_repayment: "",
        //   days_left_for_billing_cycle: "",
        //   card_info: {
        //     bank_name: "SBI",
        //     card_no: " ",
        //     name: "Abhishek Kumar Ram",
        //     cvv: "",
        //     valid_till: "",
        //     type: "Visa",
        //     img: "https://upload.wikimedia.org/wikipedia/commons/5/5e/Visa_Inc._logo.svg",
        //   },
        //   card_type: "credit",
        // },
        {
          //correct
          statement_generated_on: 20,
          billing_cycle_start_date: "",
          billing_cycle_end_date: "",
          billing_cycle_dates: "",
          payment_due_on: 9,
          payment_due_date_is_in_same_month_as_billing_cycle_end_date: false,
          payment_due_date: "",
          days_left_for_repayment: "",
          days_left_for_billing_cycle: "",
          card_info: {
            bank_name: "HDFC (Millennia)",
            card_no: "4341 5501 0632 3169",
            name: "Abhishek Kumar Ram",
            cvv: "890",
            valid_till: "07/28",
            type: "Visa Signature",
            img: "https://upload.wikimedia.org/wikipedia/commons/5/5e/Visa_Inc._logo.svg",
            // img: "https://i.imgur.com/bbPHJVe.png",
          },
          // background_img:"assets/hdfc.png",
          background_img:"https://www.hdfcbank.com/content/api/contentstream-id/723fb80a-2dde-42a3-9793-7ae1be57c87f/a6470e7a-ddb5-4512-a4a9-d628c97eb24e/Personal/Pay/Cards/Millennia%20Cards/Millennia%20Credit%20Card/Millennia-CC_new.png",
          card_type: "credit",
        },
        {
          //correct
          statement_generated_on: 5,
          payment_due_on: 23,
          payment_due_date_is_in_same_month_as_billing_cycle_end_date: true,
          billing_cycle_start_date: "",
          billing_cycle_end_date: "",
          billing_cycle_dates: "",      
          payment_due_date: "",
          days_left_for_repayment: "",
          days_left_for_billing_cycle: "",
          card_info: {
            bank_name: "KOTAK (PVR)",
            card_no: "4363 8813 1050 9837",
            name: "Abhishek Kumar Ram",
            cvv: "485",
            valid_till: "01/28",
            type: "visa gold",
            img: "https://upload.wikimedia.org/wikipedia/commons/5/5e/Visa_Inc._logo.svg",
          },
          background_img:"https://cardinsider.com/wp-content/uploads/2021/06/PVR-Kotak-Gold-Credit-Card-2.png.webp",
          card_type: "credit",
        },
        {
          //correct
          statement_generated_on: 15,
          payment_due_on: 5,
          payment_due_date_is_in_same_month_as_billing_cycle_end_date: false,
          billing_cycle_start_date: "",
          billing_cycle_end_date: "",
          billing_cycle_dates: "",      
          payment_due_date: "",
          days_left_for_repayment: "",
          days_left_for_billing_cycle: "",
          card_info: {
            bank_name: "IndusInd Bank (legend)",
            card_no: "4147 5243 1527 3743",
            name: "Abhishek Kumar Ram",
            cvv: "993",
            valid_till: "06/29",
            type: "visa signature",
            img: "https://upload.wikimedia.org/wikipedia/commons/5/5e/Visa_Inc._logo.svg",
          },
          background_img:"https://cardinsider.com/wp-content/uploads/2021/07/IndusInd-Legend-Credit-Card-2.png.webp",
          card_type: "credit",
        },
        {
          //correct
          statement_generated_on: 24,
          payment_due_on: 11,
          payment_due_date_is_in_same_month_as_billing_cycle_end_date: false,
          billing_cycle_start_date: "",
          billing_cycle_end_date: "",
          billing_cycle_dates: "",    
          payment_due_date: "",
          days_left_for_repayment: "",
          days_left_for_billing_cycle: "",
          card_info: {
            bank_name: "IDFC First (Select)",
            card_no: "5499 4710 0738 6022",
            name: "Abhishek Kumar Ram",
            cvv: "061",
            valid_till: "07/28",
            type: "Master Crad",
            img: "https://upload.wikimedia.org/wikipedia/commons/5/5e/Visa_Inc._logo.svg",
          },
          background_img:"https://cardinsider.com/wp-content/uploads/2021/08/IDFC-First-select-credit-card.png.webp",
          card_type: "credit",
        },
        {
          // data correct
          statement_generated_on: 12,
          payment_due_on: 30,
          payment_due_date_is_in_same_month_as_billing_cycle_end_date: true,
          billing_cycle_start_date: "",
          billing_cycle_end_date: "",
          billing_cycle_dates: "",
          payment_due_date: "",
          days_left_for_repayment: "",
          days_left_for_billing_cycle: "",
          card_info: {
            bank_name: "Amazon Pay ICICI",
            card_no: "4315 8151 9634 5006",
            name: "Abhishek Kumar Ram",
            cvv: "303",
            valid_till: "07/31",
            type: "Visa",
            img: "https://upload.wikimedia.org/wikipedia/commons/5/5e/Visa_Inc._logo.svg",
          },
          background_img:"https://cardinsider.com/wp-content/uploads/2021/07/Amazon-Pay-ICICI-Bank-Credit-Card.webp",
          card_type: "credit",
        },
        {
          // data correct
          statement_generated_on: 17,
          payment_due_on: 5,
          payment_due_date_is_in_same_month_as_billing_cycle_end_date: false,
          billing_cycle_start_date: "",
          billing_cycle_end_date: "",
          billing_cycle_dates: "",
          payment_due_date: "",
          days_left_for_repayment: "",
          days_left_for_billing_cycle: "",
          card_info: {
            bank_name: "HSBC",
            card_no: "4862 6989 3094 5795",
            name: "Abhishek Ram",
            cvv: "347",
            valid_till: "08/30",
            type: "Visa Platinum",
            img: "https://upload.wikimedia.org/wikipedia/commons/5/5e/Visa_Inc._logo.svg",
          },
          background_img:"https://69ba5cba.rocketcdn.me/wp-content/uploads/2021/08/HSBC-Visa-Platinum-Credit-Card.webp",
          card_type: "credit",
        },
        {
          // data correct
          statement_generated_on: 14, // 14 sep
          payment_due_on: 4, // 4 oct
          payment_due_date_is_in_same_month_as_billing_cycle_end_date: false,
          billing_cycle_start_date: "",
          billing_cycle_end_date: "",
          billing_cycle_dates: "",
          payment_due_date: "",
          days_left_for_repayment: "",
          days_left_for_billing_cycle: "",
          card_info: {
            bank_name: "Pixel Play HDFC",
            card_no: "4421 4400 0068 6734",
            name: "Abhishek Kumar Ram",
            cvv: "591",
            valid_till: "09/31",
            type: "Master Card",
            img: "https://upload.wikimedia.org/wikipedia/commons/5/5e/Visa_Inc._logo.svg",
          },
          background_img:"https://69ba5cba.rocketcdn.me/wp-content/uploads/2024/03/HDFC-Bank-Pixel-Play-Credit-Card.webp",
          card_type: "credit",
          statement_pass:"ABHI6734"
        },
        {
          // no data correct
          statement_generated_on: 12,
          payment_due_on: 2,
          payment_due_date_is_in_same_month_as_billing_cycle_end_date: false,
          billing_cycle_start_date: "",
          billing_cycle_end_date: "",
          billing_cycle_dates: "",
          payment_due_date: "",
          days_left_for_repayment: "",
          days_left_for_billing_cycle: "",
          card_info: {
            bank_name: "Flipkart Axis",
            card_no: "5334 6700 4887 3570",
            name: "Abhishek Kumar Ram",
            cvv: "007",
            valid_till: "09/29",
            type: "Master Card",
            img: "https://upload.wikimedia.org/wikipedia/commons/5/5e/Visa_Inc._logo.svg",
          },
          background_img:"https://69ba5cba.rocketcdn.me/wp-content/uploads/2021/08/Flipkart-Axis-Bank-Credit-Card.webp",
          card_type: "credit",
          statement_pass:"ABHI3570"
        },

        // debit cards
        {
          card_info: {
            bank_name: "HDFC (Millennia)",
            card_no: "4355 8401 0207 3980",
            name: "Abhishek Kumar Ram",
            cvv: "006",
            valid_till: "09/25",
            type: "Visa Signature",
            img: "https://upload.wikimedia.org/wikipedia/commons/5/5e/Visa_Inc._logo.svg",
          },
          account_info: {
            account_no: "5010 0457 7085 45",
            ifsc: "HDFC0004228",
            c_id: "177681788",
            // upi:"itisabikumar@kotak"
          },
          card_type: "debit",
        },
        {
          card_info: {
            bank_name: "IndusInd Bank (Titanium)",
            card_no: "5256 2204 1715 4550",
            name: "Abhishek Kumar Ram",
            cvv: "871",
            valid_till: "05/29",
            type: "Master card",
            img: "https://upload.wikimedia.org/wikipedia/commons/5/5e/Visa_Inc._logo.svg",
          },
          account_info: {
            account_no: "158094113042",
            ifsc: "INDB0000008",
            c_id: "76794287",
            upi: "",
          },
          card_type: "debit",
        },
        {
          card_info: {
            bank_name: "Kotak",
            card_no: "4594 5301 7928 6612",
            name: "Abhishek Kr Ram",
            cvv: "147",
            valid_till: "06/29",
            type: "Visa Classic",
            img: "https://upload.wikimedia.org/wikipedia/commons/5/5e/Visa_Inc._logo.svg",
          },
          account_info: {
            account_no: "3212785949",
            ifsc: "KKBK0006742",
            c_id: "232013907",
            upi: "itisabikumar@kotak",
          },
          card_type: "debit",
        },
        {
          card_info: {
            bank_name: "Paytm payments bank",
            card_no: "4389 7601 4229 0842",
            name: "Abhishek Kumar Ram",
            cvv: "118",
            valid_till: "04/30",
            type: "Visa",
            img: "https://upload.wikimedia.org/wikipedia/commons/5/5e/Visa_Inc._logo.svg",
          },
          account_info: {
            account_no: "918094113042",
            ifsc: "PYTM0123456",
            c_id: "",
            upi: "",
          },
          card_type: "debit",
        },
        {
          card_info: {
            bank_name: "Fi (Federal Bank)",
            card_no: "4187 3064 2359 1773",
            name: "Abhishek Kumar Ram",
            cvv: "127",
            valid_till: "09/27",
            type: "Visa",
            img: "https://upload.wikimedia.org/wikipedia/commons/5/5e/Visa_Inc._logo.svg",
          },
          account_info: {
            account_no: "5555 0121 8591 42",
            ifsc: "FDRL0005555",
            c_id: "",
            upi: "abhishekr358@fbl",
          },
          card_type: "debit",
        },
        {
          card_info: {
            bank_name: "Niyo X (Equitas Small Finance Bank)",
            card_no: "4061 5658 0130 4676",
            name: "Abhishek K Ram",
            cvv: "893",
            valid_till: "12/28",
            type: "Visa Platinum",
            img: "https://upload.wikimedia.org/wikipedia/commons/5/5e/Visa_Inc._logo.svg",
          },
          account_info: {
            account_no: "100023771742",
            ifsc: "ESFB0003024",
            c_id: "",
            upi: "niyo8094113042@equitasbank",
          },
          card_type: "debit",
        },

        // Add more cards here
      ],
      fingerprint: "",
      loginError: null,
      show_auth: true,
      userInput: "",
      // Encrypt the password
      cp: "U2FsdGVkX1865zjHj5gOapeJzl54NjhPZAt2V7aWvsQ=",
    };
  },
  computed: {
    filteredCards() {
      // Filter cards based on active tab
      return this.cards.filter((card) => card.card_type === this.activeTab);
    },
  },
  mounted() {
    console.log('mounted')
    // Check if the encrypted password is already stored in local storage
    const storedEncryptedPassword = localStorage.getItem("encryptedPassword");

    if (storedEncryptedPassword) {

      // Automatically validate the password if stored
      if (this.cp == storedEncryptedPassword) {
        this.show_auth = false;
      }
    }
  },
  created() {
    this.calculateDates();
    // console.log(this.cp, "cp");
  },
  methods: {
    
    validatePassword() {
      // Decrypt the stored password
      const bytes = CryptoJS.AES.decrypt(this.cp, "secret key 123");
      const originalPassword = bytes.toString(CryptoJS.enc.Utf8);

      if (this.userInput === originalPassword) {
        // Store the encrypted password in local storage
        localStorage.setItem("encryptedPassword", this.cp);

        // Hide the authentication form
        this.show_auth = false;
      } else {
        alert("Password is incorrect");
      }
    },
    cardBackgroundStyle(card) {
      // Conditionally add background image if present and non-empty
      if (card.background_img && card.background_img.trim() !== "") {
        return {
          backgroundImage: `url(${card.background_img})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        };
      }
      return {}; // Return an empty style object if no background image
    },
    copy_value(value) {
      navigator.clipboard.writeText(value).then(
        () => {
          this.$toast.open({
            message: `${value} copied to clipboard!`,
            type: 'success',
          });
          console.log("Text copied to clipboard");
        },
        (err) => {
          console.error("Failed to copy text: ", err);
        }
      );
    },
    copy(value) {
      let text = value.replace(/\s/g, "");

      navigator.clipboard.writeText(text).then(
        () => {
          this.$toast.open({
            message: `${text} copied to clipboard!`,
            type: 'success',
          });
          console.log("Text copied to clipboard");
        },
        (err) => {
          console.error("Failed to copy text: ", err);
        }
      );
    },
    calculateDates() {
      const today = moment();
      this.today = today.format("DD-MMM-YYYY");

      for (let card of this.cards) {
        if (card.card_type === "credit") {
          // Perform calculations only for credit cards
          card.billing_cycle_dates = this.calculateBillingCycle(today, card);
          card.payment_due_date = this.calculatePaymentDueDate(today, card);
          card.days_left_for_repayment = this.calculateDaysLeftForRepayment(today, card);
          card.days_left_for_billing_cycle = this.calculateDaysLeftForBillingCycle(today, card);
        }
      }
      this.cards = this.cards.sort((a, b) => b.days_left_for_repayment - a.days_left_for_repayment);
    },
    calculateBillingCycle(today, card) {
      let billingCycleStart = moment(today).date(card.statement_generated_on).subtract(1, "months").add(1, "days");
      let billingCycleEnd = moment(today).date(card.statement_generated_on);
      if (billingCycleEnd.isBefore(today)) {
        billingCycleStart = billingCycleStart.add(1, "months");
        billingCycleEnd = billingCycleEnd.add(1, "months");
      }
      card.billing_cycle_start_date = billingCycleStart.format("DD-MMM-YYYY");
      card.billing_cycle_end_date = billingCycleEnd.format("DD-MMM-YYYY");
      return `${moment(card.billing_cycle_start_date).format("DD-MMM")} to ${moment(card.billing_cycle_end_date).format("DD-MMM")}`;
    },
    calculatePaymentDueDate(today, card) {
      let paymentDueDate = moment(card.billing_cycle_end_date, "DD-MMM-YYYY").date(card.payment_due_on);
      if (!card.payment_due_date_is_in_same_month_as_billing_cycle_end_date) {
        paymentDueDate = paymentDueDate.add(1, "months");
      }
      return paymentDueDate.format("DD-MMM-YYYY");
    },
    calculateDaysLeftForRepayment(today, card) {
      let paymentDueDate = moment(card.payment_due_date, "DD-MMM-YYYY");
      return paymentDueDate.diff(today, "days");
    },
    calculateDaysLeftForBillingCycle(today, card) {
      let days_left_for_billing_cycle = moment(card.billing_cycle_end_date, "DD-MMM-YYYY");
      return days_left_for_billing_cycle.diff(today, "days");
    },
    getDate(date) {
      return moment(date).format("DD-MMM");
    },
  },
};
</script>

<style>
.text-shadow{
  text-shadow: -2px 4px 3px rgb(0 0 0);
}

.pointer {
  cursor: pointer;
}
</style>
